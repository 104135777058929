import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { map, mergeMap } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';
import {
  faUserEdit,
  faUserCog,
  faSignOutAlt,
  faBars,
  faUserCircle,
  faCog,
  faCircleQuestion,
  faGears,
} from '@fortawesome/pro-solid-svg-icons';

import { HeaderStateService } from '../services/header-state.service';
import { CognitoApiService } from '../services/cognito-api.service';
import { MainMenuStateService } from '../services/main-menu-state.service';
import { getQueryResult } from '../feature/shared/utilities';
import { TenantIDService } from '../services/tenant-id.service';
import { ViewerUserSummaryGQL } from 'src/generated/graphql';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatDividerModule } from '@angular/material/divider';
import { NgIf } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { LetDirective } from '@ngrx/component';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  imports: [
    FontAwesomeModule,
    MatDividerModule,
    NgIf,
    MatToolbarModule,
    MatMenuModule,
    LetDirective,
    MatButtonModule,
  ],
})
export class HeaderComponent implements OnInit {
  faUserEdit = faUserEdit;
  faUserCog = faUserCog;
  faSignOutAlt = faSignOutAlt;
  faBars = faBars;
  faUserCircle = faUserCircle;
  faCog = faCog;
  faGears = faGears;
  readonly faCircleQuestion = faCircleQuestion;

  // we don't want to fetch the user roles as tenant id is not defined on switch tenants screen
  readonly username$ = this.userSummaryGQL.watch().valueChanges.pipe(
    getQueryResult(),
    map(({ result }) => {
      if (result?.data) {
        const { viewerUser } = result.data;
        return viewerUser.givenName + ' ' + viewerUser.familyName;
      } else {
        return '';
      }
    }),
  );

  readonly state$ = combineLatest([
    this.mainMenuStateService.menuState$,
    this.headerStateService.isLoggedIn,
    this.tenantIDService.tenantID$,
    this.username$,
    this.headerStateService.icon$,
  ]).pipe(
    map(([menuState, isLoggedIn, tenantID, username, icon]) => ({
      menuState,
      isLoggedIn,
      tenantID,
      username,
      icon,
    })),
  );

  constructor(
    private headerStateService: HeaderStateService,
    public router: Router,
    private apollo: Apollo,
    private cognitoApiService: CognitoApiService,
    private userSummaryGQL: ViewerUserSummaryGQL,
    private mainMenuStateService: MainMenuStateService,
    private tenantIDService: TenantIDService,
  ) {}

  ngOnInit() {
    this.headerStateService.updateIcon();
  }

  onLogoutClick() {
    this.cognitoApiService
      .signOut()
      .pipe(mergeMap(() => of(this.apollo.client.clearStore())))
      .subscribe({
        next: () => {
          this.headerStateService.setIsLoggedIn(false);
          this.tenantIDService.clearTenant();
          this.router.navigate(['/login']);
        },
        error: (err) => console.log(err),
      });
  }

  onMenuClicked() {
    this.mainMenuStateService.toggleMainMenuState();
  }

  onMenuItemClicked(url: string) {
    this.router.navigateByUrl(url);
  }
}
