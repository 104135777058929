import { Injectable } from '@angular/core';
import {
  confirmResetPassword,
  confirmSignIn,
  fetchAuthSession,
  fetchMFAPreference,
  fetchUserAttributes,
  getCurrentUser,
  signIn,
  signOut,
  updateMFAPreference,
  UpdateMFAPreferenceInput,
  updatePassword,
  setUpTOTP,
  verifyTOTPSetup,
} from '@aws-amplify/auth';
import { from, switchMap, tap, map, throwError } from 'rxjs';
import { updateSentryScope } from 'src/sentry';
import { catchError } from 'rxjs/operators';

export enum MFAMethod {
  SMS = 'sms',
  TOTP = 'totp',
}

@Injectable({
  providedIn: 'root',
})
export class CognitoApiService {
  constructor() {
    // do nothing.
  }

  // Todo: if used more frequent then add ShareReplay() function from RxJs
  currentSession() {
    return from(fetchAuthSession());
  }

  accessToken() {
    return this.currentSession().pipe(map((s) => s.tokens?.accessToken));
  }

  authenticatedUser() {
    return from(
      Promise.all([fetchAuthSession(), getCurrentUser()]).then(
        ([session, user]) => ({ ...session, ...user }),
      ),
    );
  }

  changePassword(oldPassword: string, newPassword: string) {
    return this.authenticatedUser().pipe(
      switchMap((user) => {
        updateSentryScope(user.username, null);

        return from(updatePassword({ oldPassword, newPassword }));
      }),
    );
  }

  signOut() {
    return from(signOut());
  }

  signIn(username: string, password: string) {
    return from(signIn({ username, password })).pipe(
      tap(async (user) => {
        if (!user.isSignedIn) {
          return;
        }
        try {
          const user = await getCurrentUser();
          updateSentryScope(user.username, null);
        } catch {}
      }),
    );
  }

  completeNewPassword(password: string) {
    return from(confirmSignIn({ challengeResponse: password })).pipe(
      tap(async (resp) => {
        if (!resp.isSignedIn) return;

        try {
          const user = await getCurrentUser();
          updateSentryScope(user.username, null);
        } catch {}
      }),
    );
  }

  forgotPasswordSubmit(email: string, code: string, password: string) {
    return from(
      confirmResetPassword({
        username: email,
        confirmationCode: code,
        newPassword: password,
      }),
    );
  }

  fetchUserAttributesAndMFAPreference() {
    return this.authenticatedUser().pipe(
      switchMap((user) => {
        updateSentryScope(user.username, null);
        return from(
          Promise.all([fetchMFAPreference(), fetchUserAttributes()]).then(
            ([mfaPreference, userAttributes]) => ({
              ...mfaPreference,
              ...userAttributes,
            }),
          ),
        );
      }),
    );
  }

  changeMFAPreference(mfaPreference: MFAMethod | '') {
    return this.authenticatedUser().pipe(
      switchMap((user) => {
        updateSentryScope(user.username, null);

        const input: UpdateMFAPreferenceInput = {
          sms: 'DISABLED',
          totp: 'DISABLED',
        };
        if (mfaPreference && mfaPreference === MFAMethod.TOTP) {
          input.totp = 'PREFERRED';
        } else if (mfaPreference && mfaPreference === MFAMethod.SMS) {
          input.sms = 'PREFERRED';
        }
        return from(updateMFAPreference(input));
      }),
    );
  }

  setUpTOTP() {
    return this.authenticatedUser().pipe(
      switchMap((user) => {
        updateSentryScope(user.username, null);

        return from(setUpTOTP());
      }),
    );
  }

  completeTOTPSetup(code: string) {
    return this.authenticatedUser().pipe(
      switchMap((user) => {
        updateSentryScope(user.username, null);

        return from(verifyTOTPSetup({ code }));
      }),
    );
  }

  completeMFASignIn(mfaCode: string) {
    return from(confirmSignIn({ challengeResponse: mfaCode })).pipe(
      catchError((err) => {
        return throwError(() => {
          if (err.message.toLowerCase('code mismatch')) {
            return new Error(
              `The code you entered is incorrect. Please try again.`,
            );
          }
          console.log(err);
          return new Error(`Unexpected error.`);
        });
      }),
    );
  }
}
