<ng-template #defaultLogo>
  <div class="logo-container" style="margin: 5px 0">
    <img
      class="default-logo"
      src="assets/elegant-logo-no-tagline.png"
      alt="logo"
    />
  </div>
</ng-template>
<ng-container *ngrxLet="state$ as state">
  <mat-toolbar>
    <div
      class="logo-container"
      *ngIf="state.icon && state.icon !== ''; else defaultLogo"
    >
      <img
        class="elegant-logo"
        src="assets/elegant-icon.png"
        alt="elegant-logo"
      />
      <img class="company-logo" src="{{ state.icon }}" alt="company-logo" />
    </div>
    <div class="content" *ngIf="state.isLoggedIn && state.tenantID">
      <h3 class="mat-h3 primary-typography">
        {{ state.username }}
      </h3>
      <button
        mat-icon-button
        class="user-account-button"
        [matMenuTriggerFor]="menu"
        [disabled]="state.menuState"
      >
        <fa-icon
          size="2x"
          [icon]="faUserCircle"
          class="primary-icon account-icon"
        ></fa-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="onMenuItemClicked('profile')">
          <fa-icon
            size="lg"
            [icon]="faUserEdit"
            class="accent-icon menu-item-icon"
            [fixedWidth]="true"
          ></fa-icon>
          <span class="mat-h4 primary-typography">My Profile</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="onMenuItemClicked('preferences')">
          <fa-icon
            size="lg"
            [icon]="faCog"
            class="accent-icon menu-item-icon"
            [fixedWidth]="true"
          ></fa-icon>
          <span class="mat-h4 primary-typography">Preferences</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="onMenuItemClicked('change-password')">
          <fa-icon
            size="lg"
            [icon]="faUserCog"
            class="accent-icon menu-item-icon"
            [fixedWidth]="true"
          ></fa-icon>
          <span class="mat-h4 primary-typography">Change Password</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="onMenuItemClicked('setup-mfa')">
          <fa-icon
            size="lg"
            [icon]="faGears"
            class="accent-icon menu-item-icon"
            [fixedWidth]="true"
          ></fa-icon>
          <span class="mat-h4 primary-typography">Setup MFA</span>
        </button>
        <mat-divider></mat-divider>
        <button mat-menu-item routerLink="/help">
          <fa-icon
            size="lg"
            [icon]="faCircleQuestion"
            class="accent-icon menu-item-icon"
            [fixedWidth]="true"
          ></fa-icon>
          <span class="mat-h4 primary-typography">Help</span>
        </button>

        <mat-divider></mat-divider>
        <button mat-menu-item (click)="onLogoutClick()">
          <fa-icon
            class="accent-icon menu-item-icon"
            size="lg"
            [icon]="faSignOutAlt"
            [fixedWidth]="true"
          ></fa-icon>
          <span class="mat-h4 primary-typography">Logout</span>
        </button>
      </mat-menu>
      <button
        mat-icon-button
        class="accent-icon"
        type="button"
        (click)="onMenuClicked()"
      >
        <fa-icon size="sm" [icon]="faBars" class="menu-icon"></fa-icon>
      </button>
    </div>
    <button
      *ngIf="state.isLoggedIn === false"
      mat-flat-button
      color="accent"
      routerLink="login"
    >
      LOGIN
    </button>
  </mat-toolbar>
</ng-container>
